<template>
  <div>
    <div id="firmatip-grid-container">
      <b-form-group>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.firmatipadd-modal
          class="d-flex align-items-left"
          squared
          variant="outline-primary"
        >
          <feather-icon
            icon="AddIcon"
            class="mr-50"
          />
          <span>Firma Tip Ekle</span>
        </b-button>
      </b-form-group>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        placeholder:'Firma Tip ara'}"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      theme="my-theme"
      @on-row-click="onDataGridItemClicked"
    >
      <div slot="emptystate">
        <center> Firma Tip kaydı bulunmuyor.</center>
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <template>
              <b-button-group
                class="mt-25"
                size="sm"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  squared
                  class="mr-1"
                  variant="outline-warning"
                  @click="onUpdateFirmaTipBtnClicked(props.row)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Düzenle</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  size="sm"
                  squared
                  variant="outline-danger"
                  @click="onDeleteFirmaTipBtnClicked(props.row)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Sil</span>
                </b-button>
              </b-button-group>
            </template>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              1 ila
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','20','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> {{ props.total }} kayıt gösteriliyor </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- Firma Tip EKLE MODAL -->
    <b-modal
      id="firmatipadd-modal"
      cancel-variant="outline-secondary"
      ok-title="Ekle"
      cancel-title="İptal"
      centered
      no-close-on-backdrop
      title="Firma Tipi Ekle"
      @ok="onModalSaveFirmaTip"
      @cancel="onModalCancelled"
    >
      <b-form>
        <b-form-group>
          <label for="firmTypeName">Firma Tip Adı:</label>
          <b-form-input
            id="firmTypeName"
            v-model="newFirmTypeName"
            type="text"
            placeholder="Firma Tip Adı"
            :state="newFirmTypeName.length > 0"
          />
          <b-form-invalid-feedback>
            Firma Tip adı boş bırakılamaz.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </b-modal>
    <!-- Firma Tip Güncelle  MODAL -->
    <b-modal
      id="firmatipguncelle-modal"
      cancel-variant="outline-secondary"
      ok-title="Güncelle"
      cancel-title="İptal"
      centered
      no-close-on-backdrop
      title="Firma Tip Güncelle"
      @ok="onModalUpdateFirmaTip"
      @cancel="onModalCancelled"
    >
      <b-form>
        <b-form-group hidden>
          <label for="firmTypeId">Identity</label>
          <b-form-input
            id="FirmTypeId"
            v-model="newFirmTypeId"
            disabled
            type="text"
          />
        </b-form-group>
        <b-form-group>
          <label for="firmTypeName">Firma Tip Adı:</label>
          <b-form-input
            id="firmTypeName"
            v-model="updFirmTypeName"
            type="text"
            placeholder="Firma Tip Adı"
            :state="updFirmTypeName.length > 0"
          />
          <b-form-invalid-feedback>
            Firma Tip adı boş bırakılamaz.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import * as apiFirmaTip from '@/api/tanimlar/firmtype'
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BButtonGroup, BButton, BModal, BForm, VBModal, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'SektorList',
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButtonGroup,
    BButton,
    BModal,
    BForm,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      newFirmTypeId: -1,
      newFirmTypeName: '',
      updFirmTypeName: '',
      pageLength: 15,
      columns: [
        {
          label: 'Firma Tip Adı',
          field: 'firmTypeName',
        },
        {
          label: 'İşlem',
          field: 'action',
          sortable: false,
          width: '15%',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {

  },
  created() {
    apiFirmaTip.getFirmTypesAll().then(res => {
      this.rows = res.data.list
    })
  },
  mounted() {
    this.getFirmTypeList()
  },
  methods: {
    getFirmTypeList() {
      apiFirmaTip.getFirmTypesAll().then(res => {
        this.rows = res.data.list
      })
    },
    clearModal() {
      this.newFirmTypeName = ''
      this.updFirmTypeName = ''
    },
    onDataGridItemClicked(params) {
      return params
    },
    onModalSaveFirmaTip() {
      if (this.newFirmTypeName.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Kayıt Eklenemedi',
            text: 'Boş kayıt eklenemez.',
            icon: 'PlusSquareIcon',
            variant: 'danger',
          },
        })
        return
      }
      const firmTypeItem = {
        firmTypeId: null,
        firmTypeName: this.newFirmTypeName,
      }
      apiFirmaTip.addFirmType(firmTypeItem).then(() => {
        this.getFirmTypeList()
      })
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Firma Tipi Ekle',
          text: 'Yeni kayıt eklendi',
          icon: 'PlusSquareIcon',
          variant: 'success',
        },
      })
      this.clearModal()
    },
    onModalCancelled() {
      this.clearModal()
    },
    onUpdateFirmaTipBtnClicked(row) {
      this.newFirmTypeId = row.firmTypeId
      this.updFirmTypeName = row.firmTypeName
      this.$bvModal.show('firmatipguncelle-modal')
    },
    onDeleteFirmaTipBtnClicked(row) {
      this.$bvModal.msgBoxConfirm(` '${row.firmTypeName}' tanımı silinecek, onaylıyor musunuz?`, {
        title: 'Uyarı!',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'Evet',
        cancelTitle: 'Hayır',
        footerClass: 'p-2',
        hideHeaderClose: true,
        noCloseOnBackdrop: true,
        centered: true,
      })
        .then(value => {
          if (value) {
            apiFirmaTip.deleteFirmType(row.firmTypeId).then(() => {
              this.getFirmTypeList()
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Kayıt Silindi',
                text: `${row.firmTypeName} kaydı silindi.`,
                icon: 'DeleteIcon',
                variant: 'danger',
              },
            })
          }
        })
      this.clearModal()
    },
    onModalUpdateFirmaTip() {
      if (this.updFirmTypeName.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Kayıt Güncellenemedi',
            text: 'Boş kayıt güncellenemez.',
            icon: 'PlusSquareIcon',
            variant: 'danger',
          },
        })
        return
      }
      const firmTypeItem = {
        firmTypeId: this.newFirmTypeId,
        firmTypeName: this.updFirmTypeName,
      }
      apiFirmaTip.updateFirmType(firmTypeItem).then(() => {
        this.getFirmTypeList()
      })
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Kayıt Güncellendi',
          text: 'Kayıt başarıyla güncellendi',
          icon: 'EditIcon',
          variant: 'warning',
        },
      })
      this.clearModal()
    },
  },
}
</script>
<style lang='scss'>
   @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
