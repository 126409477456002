import request from '@/utils/request'

export function getFirmTypeById(data) {
  return request({
    url: `/api/FirmType/GetFirmTypeById/${data}`,
    method: 'get',
  })
}

export function getFirmTypesAll() {
  return request({
    url: '/api/FirmType/GetFirmTypesAll',
    method: 'get',
  })
}

export function addFirmType(data) {
  return request({
    url: '/api/FirmType/AddFirmType',
    method: 'post',
    data,
  })
}

export function updateFirmType(data) {
  return request({
    url: '/api/FirmType/UpdateFirmType',
    method: 'put',
    data,
  })
}
export function deleteFirmType(data) {
  return request({
    url: `/api/FirmType/DeleteFirmType?firmTypeId=${data}`,
    method: 'delete',
  })
}
